<template>
  <div class="mt-8">
    <CurrencyInput
      placeholder="Amount"
      width="w-full"
      class="mt-10"
      v-model="card.amount"
      type="number"
      errorText="Amount must be at least N100"
      :validation="rule"
      @valid="valid.amount = $event"
    />
    <div class="mt-8">
      <CardSelect v-model="card.tokenizedCardId" />
    </div>
    <Button
      text="Fund wallet"
      width="w-full"
      class="mt-16"
      @click="submitData"
      :loading="loading"
      :disabled="!rule"
    />
  </div>
</template>
<script>
  import { mapActions, mapState } from "vuex";
  import { Button } from "@/UI/Button";
  import CardSelect from "@/UI/CardSelect";
  import { CurrencyInput } from "@/UI/Input";

  export default {
    components: {
      Button,
      CardSelect,
      CurrencyInput,
    },
    data: () => ({
      fund: "card",
      card: {
        amount: 0,
        tokenizedCardId: "",
      },
      loading: false,
      cardLoading: true,
      valid: {
        amount: false,
      },
    }),
    computed: {
      ...mapState({
        userCards: (state) => state.dashboard.userCards,
      }),
      rule() {
        return this.card.amount > 99;
      },
    },
    // watch: {
    //   "card.amount"(value) {
    //     console.log(value > 0);
    //   },
    // },
    // created() {
    //   this.getUserCards();
    // },
    methods: {
      ...mapActions("dashboard", ["fundWithCard", "getUserWallet"]),
      ...mapActions("notification", ["showAlert"]),
      submitData() {
        if (Object.values(this.valid).includes(false)) {
          this.showAlert({
            display: true,
            description: "Please enter a valid amount",
            type: "error",
            title: "Validation Error",
          });
          return false;
        } else {
          this.loading = true;
          this.fundWithCard(this.card)
            .then(() => {
              // console.log(data.data.status , 'fund')
              this.loading = false;
              this.getUserWallet();
              this.$emit("close");
            })
            .catch(() => {
              this.loading = false;
            });
        }
      },
      getUserCards() {
        this.cardLoading = true;
        this.getCards()
          .then(() => {
            this.cardLoading = false;
          })
          .catch(() => {
            this.cardLoading = false;
          });
      },
    },
  };
</script>
<style scoped>
  @import "~@/assets/styles/animation.css";
</style>
