var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-8" },
    [
      _c("CurrencyInput", {
        staticClass: "mt-10",
        attrs: {
          placeholder: "Amount",
          width: "w-full",
          type: "number",
          errorText: "Amount must be at least N100",
          validation: _vm.rule,
        },
        on: {
          valid: function ($event) {
            _vm.valid.amount = $event
          },
        },
        model: {
          value: _vm.card.amount,
          callback: function ($$v) {
            _vm.$set(_vm.card, "amount", $$v)
          },
          expression: "card.amount",
        },
      }),
      _c(
        "div",
        { staticClass: "mt-8" },
        [
          _c("CardSelect", {
            model: {
              value: _vm.card.tokenizedCardId,
              callback: function ($$v) {
                _vm.$set(_vm.card, "tokenizedCardId", $$v)
              },
              expression: "card.tokenizedCardId",
            },
          }),
        ],
        1
      ),
      _c("Button", {
        staticClass: "mt-16",
        attrs: {
          text: "Fund wallet",
          width: "w-full",
          loading: _vm.loading,
          disabled: !_vm.rule,
        },
        on: { click: _vm.submitData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }